import { Performative } from './fjage'
import { gw } from './gateway'
import {
  GetActiveSwanBotReq,
  GetSwanBotForUserReq,
  GetSwanBotReq,
  PutSwanBotReq,
  GetFileReq,
  GetSwanBotIconReq,
} from './messages'
import { SwanBot, SwanBotUpdate, SwanBotAdd } from './types'
import { REQ_TIMEOUT } from './timeout'
import { invertCoordinates, haveIntersection } from './utils'
import { isAuthenticated, getUserRole, getUserId } from './common'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'

const SWANBOT_ICON_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAVCAYAAACt4nWrAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIMSURBVHgBpZXxkaowEMbjm/e/lEAHDzugA+3gUQJXgVwF0oF0gFbgWAF2gBWIV0Fuf3HXyYDO3ejO7JBA9tsv326Cc2+Y9z4VP4gP/mad+Mq9awrcV1XlkyTxvCqKggSDe9cEpNxutwE09sOBjfg1a/661y07Ho/3iYCGJ++yLKskwZd71SS4LssysM3z3Kv2DPphCCVI3asmwQUSMEzT9K61PBOSPAuCQa/e6XwzDlCQQoaAmtb5T4xaqg4b0c6vVitPR2gw+93algG3oiIRRB4BwqLUwKGu60kX2PZJ1Pd96AiNG9Ccdpxorf3qYUD2tm29aYl3XRfmm80mFM6S6E5a8crWk5hCT3S2QK38HahpGh8brNfrdQxGXXpjf7lcoJ/E4PkjtnGysZHEZLI565GU3UzYW99SxJi9SjAxNKbo9p046/kxeMqWYGNyGDtci/UwAYA8bbe6NhknKAG0iwh5LAEMnyVgjcoRYokTy/7E4LPZrBagT+4JebrFYuH2+324NwQ8zM/nsxsbawU0jOfzubterwwT98goiDF2ekBgTbvGRRzLY8z1HGTumclHLueetjPdrS05CyRg+7FUSKPN0LufzN8OWGO9bUm4HkhgNcFJRl2UdR5kdr8wfzvSlfj/3W4X7uzT6XT/jt7L5dJJUsT+kNo1vwaPklCkXP1f9AlQ/hyNAF/t5TeJ/g1hhTMpVgAAAABJRU5ErkJggg=='

const entityToSwanbot = (sb: any): SwanBot => ({
  id: sb.id,
  name: sb.name,
  areaId: sb.areaId,
  home: [sb.homeLon, sb.homeLat],
  status: sb.status,
  registrationDate: sb.createdDate,
  lastLoginDate: sb.lastLoginTime,
  currMissionId: sb.currentMissionId,
  currMissionName: sb.currentMissionName,
  currRunId: sb.currentRunId,
  currRunDate: sb.runStartDate,
  currLocation: sb.lastKnownLocation,
  currHeading: sb.lastKnownHeading,
  batteryLevel: sb.batteryLevel,
  iconColor: sb.iconColor,
  modifiedUserId: sb.modifiedUserId,
  delFlag: sb.delFlag,
  swanbotKey: sb.swanbotKey,
})

/**
 * Fetches all the swanbots.
 * @return Returns a Promise which resolves to an array of SwanBot objects if successful, else rejects with an Error.
 **/
export const getAllSwanBots = async (): Promise<SwanBot[]> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let req = new GetSwanBotReq()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  )
    return Promise.reject(new ValidationError('GetSwanBotReq failed.'))
  if (rsp && !rsp.swanbots)
    return Promise.reject(new ValidationError('No SwanBots found.'))
  return rsp.swanbots.map(entityToSwanbot)
}

/**
 * Fetches the swanbot details for a given user Id. This list contains SwanBots with deleted status also.
 * @return Returns a Promise which resolves to an array of SwanBot objects if successful, else rejects with an Error.
 */
export async function getSwanBotsForUser(): Promise<SwanBot[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let req = new GetSwanBotForUserReq()
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)

  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  ) {
    return Promise.reject(new ValidationError('GetSwanBotForUserReq failed:'))
  }

  if (rsp && !rsp.swanbots) {
    return Promise.reject(
      new ValidationError('No SwanBots found for the given user.')
    )
  }

  return rsp.swanbots.map(entityToSwanbot)
}

/**
 * Fetches the swanbot details for a given user Id. This list excludes SwanBots with deleted status and can be
 * @return Returns a Promise which resolves to an array of SwanBot objects if successful, else rejects with an Error.
 */
export async function getActiveSwanBotsForUser(): Promise<SwanBot[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let req = new GetActiveSwanBotReq()
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)

  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  ) {
    return Promise.reject(new ValidationError('GetActiveSwanBotsReq failed:'))
  }

  return rsp.swanbots.map(entityToSwanbot)
}

/**
 * Updates the SwanBot details. Only the name, home and area of the SwanBot can be updated and the remaining fields will be ignored.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function updateSwanBot(
  swanbotId: number,
  swanbot: SwanBotUpdate
): Promise<Boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!swanbotId) {
    return Promise.reject(new ValidationError('SwanBot Id is required'))
  }
  const req = new PutSwanBotReq()
  req.id = swanbotId
  req.name = swanbot.name ? swanbot.name : null
  req.home = swanbot.home ? invertCoordinates(swanbot.home) : null
  req.areaId = swanbot.areaId ? swanbot.areaId : null
  req.iconColor = swanbot.iconColor ? swanbot.iconColor : null
  req.userId = getUserId()
  req.operation = 'UPDATE'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('Failed to update SwanBot'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }

  return true
}

/**
 * Deletes the swanbot.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function deleteSwanBot(swanbotId: number): Promise<Boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!swanbotId) {
    return Promise.reject(new ValidationError('SwanBot ID is required.'))
  }
  const req = new PutSwanBotReq()
  req.id = swanbotId
  req.operation = 'DELETE'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('Delete SwanBot failed'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new ValidationError('Invalid SwanBot Id'))
  }

  return true
}

/**
 * Download activity log for SwanBot.
 * @return Returns a Promise which resolves to Uint8Array if successful, else rejects with an Error.
 */
export async function downloadSwanBotLog(
  swanbotId: number
): Promise<Uint8Array> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  var gfr = new GetFileReq()
  gfr.recipient = gw.agent('astridsh')
  gfr.filename = 'swanbot-logs/DAISY/20201217.txt'
  let rsp = await gw.request(gfr)
  if (rsp.perf != Performative.INFORM) return new Uint8Array()
  let filecontents = new Uint8Array(rsp.contents)
  return filecontents
}

/**
 * This method fetches the urls of the list of swanbot icons stored in the server.
 * @return Returns a Promise which resolves to the list of urls if successful, else rejects with an Error.
 */
export const getSwanBotIconList = async (): Promise<string[]> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new GetSwanBotIconReq()
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('GetSwanBotIconReq failed'))
  return rsp.iconUrls
}

/**
 * Adds a new SwanBot by entering the name, area and the public key of the SwanBot.
 * @return Returns a Promise which resolves to `id` of the new SwanBot if successful, else rejects with an Error.
 */
export async function addSwanBot(swanbot: SwanBotAdd): Promise<number> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!swanbot.name) {
    return Promise.reject(
      new ValidationError('Please provide a name for the SwanBot.')
    )
  }
  if (!swanbot.areaId) {
    return Promise.reject(
      new ValidationError('Please provide the area for the SwanBot.')
    )
  }
  if (!swanbot.swanbotKey) {
    return Promise.reject(
      new ValidationError('Please provide the public key of the SwanBot.')
    )
  }
  const req = new PutSwanBotReq()
  req.name = swanbot.name ? swanbot.name : null
  req.areaId = swanbot.areaId ? swanbot.areaId : null
  req.swanbotKey = swanbot.swanbotKey
  req.userId = getUserId()
  req.operation = 'INSERT'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('Failed to add SwanBot'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }

  return rsp.id
}
