import { Performative } from './fjage'
import { gw } from './gateway'
import {
  PutPasswordReq,
  Put2FAReq,
  Remove2FAReq,
  PutForgotPasswordReq,
  GetQRCodeReq,
  LoginReq,
  VerifyForgotPwdReq,
} from './messages'
import { REQ_TIMEOUT } from './timeout'
import { UserAuthentication } from './types'
import { AuthenticationError, ValidationError } from './error-types'
import {
  getUserId,
  getUserSession,
  isAuthenticated,
  removeUserSession,
  saveUserSession,
} from './common'

/**
 * Login to the application.
 * @param username
 * @param password
 * @param [authCode] - The 2FA code entered by the user.
 */
export async function loginUser(
  username: string,
  password: string,
  authCode?: string
): Promise<UserAuthentication> {
  if (!username) {
    return Promise.reject(new ValidationError('User name is required'))
  }
  if (!password) {
    return Promise.reject(new ValidationError('Password is required'))
  }
  let req = new LoginReq()
  req.username = username
  req.password = password
  req.authCode = authCode ? authCode : null
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  )
    return Promise.reject(new Error('Authetication failed'))

  if (rsp.valid) {
    saveUserSession({
      id: rsp.id,
      roles: rsp.roles,
    })
  }

  return {
    id: rsp.id,
    valid: rsp.valid,
    request2FA: rsp.request2FA,
  }
}

/**
 * Logout of the application.
 */
export function logoutUser(): Promise<boolean> {
  return new Promise((resolve) => {
    const currentUser = getUserSession()
    if (!currentUser) return resolve(true)
    removeUserSession()
    resolve(true)
  })
}

/**
 * The request to reset password for the user using the `Forgot Password` link.
 * @param {number} username - The username (email ID) of the user entered in the forgot password link.
 * @return Returns a Promise which resolves to true.
 */
export async function reqForgotPassword(username: string): Promise<boolean> {
  let req = new PutForgotPasswordReq()
  req.username = username
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new Error('ChangePasswordReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(new Error('Please check if the inputs are valid'))
  return rsp.resetLink
}

/**
 * Verification of new password request submitted from the link sent via email.
 */
export async function verifyForgotPassword({
  username,
  newPassword,
  authCode,
  securityToken,
}: {
  /** The username (email ID) of the user entered in the new password form. */
  username: string
  /** The new password entered by the user. */
  newPassword: string
  /** The 2FA code entered by the user. */
  authCode?: string
  /** The security token from the url sent via email. */
  securityToken: string
}): Promise<boolean> {
  let req = new VerifyForgotPwdReq()
  req.username = username
  req.newPassword = newPassword
  req.authCode = authCode ? authCode : null
  req.securityToken = securityToken
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new Error('ChangePasswordReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(new Error('Please check if the inputs are valid'))
  return true
}

/**
 * The request to change password for the user from the user profile.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function reqChangePassword(
  userId: number,
  oldPassword: string,
  newPassword: string
): Promise<boolean> {
  if (!userId) {
    return Promise.reject(new Error('User ID is required'))
  }
  if (!oldPassword) {
    return Promise.reject(new Error('Old password is required'))
  }
  if (!newPassword) {
    return Promise.reject(new Error('New password is required'))
  }
  let req = new PutPasswordReq()
  req.userId = userId
  req.oldPassword = oldPassword
  req.newPassword = newPassword
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new Error('ChangePasswordReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(new Error('Please check if the inputs are valid'))
  return true
}

/**
 * Provides the QR code to add the authenticator app.
 * @return Returns a Promise which resolves to the token, else rejects with an Error.
 */
export async function getQRCode(): Promise<string> {
  let req = new GetQRCodeReq()
  req.userId = getUserId()
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('GetQRCodeReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return rsp.qrCode
}

/**
 * Enable the 2FA authentication for the user.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function enable2FA(
  authCode: string,
  qrCode: string
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }

  let req = new Put2FAReq()
  req.userId = getUserId()
  req.authCode = authCode
  req.qrCode = qrCode
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Get2FAForUserReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}

/**
 * Disable the 2FA authentication for the user.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function disable2FA(token: string): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let req = new Remove2FAReq()
  req.userId = getUserId()
  req.token = token
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Get2FAForUserReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}
