import { Image } from './types'
import { GetImageReq } from './messages'
import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import { relativeUrlToAbsolute, imageSrc } from './utils'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated } from './common'

/**
 * Fetches the latest image captured for the given run Id. This is intended for display in the Mission execution page and in the SwanBot info card when a mission is running.
 * @return Returns a Promise which resolves to the Image object if successful, else rejects with an Error.
 */
export async function getLatestImage(
  runId: number
): Promise<Image | undefined> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  if (!runId) return Promise.reject(new ValidationError('Run ID is required'))
  const req = new GetImageReq()
  req.recipient = gw.agent('astrid')
  req.runIds = [runId]
  req.latest = true
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || rsp.perf == Performative.FAILURE)
    return Promise.reject(new ValidationError('Failed to get latest image'))
  if (rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if inputs are valid')
    )
  return rsp.images.length
    ? {
        runId: runId,
        time: rsp.images[0].dataTime,
        imageURL: imageSrc(rsp.images[0].imageBuf, rsp.images[0].mimeType),
      }
    : undefined
}

/**
 * Fetches all the images captured for the given list of run Ids. This is intended to display all the images for the selected mission runs in the Data Visualization tab.
 * @param runIds - List of selected run Ids.
 * @return Returns a Promise which resolves to an array of Image objects if successful, else rejects with an Error.
 */
export async function getAllImages(runIds: number[]): Promise<Image[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  if (!runIds.length)
    return Promise.reject(new ValidationError('Run ID is required'))
  const req = new GetImageReq()
  req.recipient = gw.agent('astrid')
  req.runIds = runIds
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || rsp.perf == Performative.FAILURE)
    return Promise.reject(new ValidationError('Failed to get latest image'))
  if (rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if inputs are valid')
    )
  const result: Image[] = rsp.images.map(
    (img: {
      dataTime: number
      imageBuf: number[]
      missionRunId: number
      mimeType: string
    }) => ({
      runId: img.missionRunId,
      time: img.dataTime,
      imageURL: imageSrc(img.imageBuf, img.mimeType),
    })
  )

  return result.sort((a, b) => a.time - b.time)
}
