import { Performative } from './fjage'
import { gw } from './gateway'
import {
  MissionType,
  NewMissionType,
  MissionTask,
  LatestMission,
} from './types'
import {
  GetMissionReq,
  GetMissionRsp,
  PutMissionReq,
  GetSurveyTypeReq,
  RemoveMissionReq,
} from './messages'
import { REQ_TIMEOUT } from './timeout'
import { isDefined, invertCoordinates, haveIntersection } from './utils'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'

const invertMissionTasksCoordinates = (missionTasks: MissionTask[]) =>
  missionTasks.map((item) => {
    if (item.type === 'go') {
      const { location } = item.args
      return {
        ...item,
        args: {
          ...item.args,
          location:
            location && location !== 'home'
              ? invertCoordinates(location)
              : location,
        },
      }
    }

    if (item.type === 'survey') {
      return {
        ...item,
        args: {
          ...item.args,
          aoi: item.args.aoi.map(invertCoordinates),
        },
      }
    }

    return item
  })

/**
 * Fetches all the missions.
 * @returns Returns a Promise which resolves to an array of Mission objects if successful, else rejects with an Error.
 */
export const getAllMissions = async (): Promise<MissionType[]> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new GetMissionReq()
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Request failed: MissionListReq'))
  else {
    return (rsp.missions as MissionType[]).map(
      (mis) =>
        ({
          id: mis.id,
          name: mis.name,
          areaId: mis.areaId,
          nominalSpeed: mis.nominalSpeed,
          pointRadius: mis.pointRadius,
          geofenceId: mis.geofenceId,
          totalDistance: mis.totalDistance,
          totalTime: mis.totalTime,
          lastMissionRun: mis.lastMissionRun,
          status: mis.status,
          missionTasks: invertMissionTasksCoordinates(mis.missionTasks || []),
          createdUserId: mis.createdUserId,
          createdUser: mis.createdUser,
          currentRunId: mis.currentRunId,
          currentRunDate: mis.currentRunDate,
        } as MissionType)
    )
  }
}

/**
 * Fetches the mission for a given Id.
 * @return Returns a Promise which resolves to the Mission object if successful, else rejects with an Error.
 */
export const getMission = async (id: number): Promise<MissionType> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) {
    return Promise.reject(new ValidationError('Mission ID is required'))
  }
  const req = new GetMissionReq()
  req.id = id
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetMissionReq failed'))
  } else if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new ValidationError('Insufficient inputs'))
  } else if (rsp && rsp instanceof GetMissionRsp) {
    const data: MissionType = {
      id: id,
      areaId: rsp.missions[0].areaId,
      name: rsp.missions[0].name,
      nominalSpeed: rsp.missions[0].nominalSpeed,
      pointRadius: rsp.missions[0].pointRadius,
      missionTasks: invertMissionTasksCoordinates(
        rsp.missions[0].missionTasks || []
      ),
      geofenceId: rsp.missions[0].geofenceId,
      totalDistance: rsp.missions[0].totalDistance,
      totalTime: rsp.missions[0].totalTime,
      lastMissionRun: rsp.missions[0].lastMissionRun,
      status: rsp.missions[0].status,
      createdUserId: rsp.missions[0].createdUserId,
      createdUser: rsp.missions[0].createdUser,
      currentRunId: rsp.missions[0].currentRunId,
      currentRunDate: rsp.missions[0].currentRunDate,
    }
    return data
  }

  return Promise.reject(new ValidationError('Something went wrong'))
}

function validateMission(mission: NewMissionType): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!mission.geofenceId) {
      reject(new ValidationError('Geofence ID is required'))
      return
    } else if (!mission.name) {
      reject(new ValidationError('Mission name is required'))
      return
    } else if (!mission.nominalSpeed) {
      reject(new ValidationError('Cruising thrust is required'))
      return
    } else if (!mission.missionTasks) {
      reject(new ValidationError('Atleast 1 mission task is required'))
      return
    } else if (mission.missionTasks) {
      mission.missionTasks.forEach((task) => {
        if (!task.type) {
          reject(new ValidationError('Task type is required'))
        } else if (task.type == 'go' && !task.args) {
          reject(new ValidationError('Mission point is required for this task'))
        } else if (task.type == 'lawnmower' && !isDefined(task.args.bearing)) {
          reject(new ValidationError('Bearing is required for LAWNMOWER task'))
        } else if (task.type == 'lawnmower' && !task.args.spacing) {
          reject(new ValidationError('Spacing is required for LAWNMOWER task'))
        } else if (task.type == 'lawnmower' && !task.args.leg) {
          reject(
            new ValidationError(
              'Length of the leg is required for LAWNMOWER task'
            )
          )
        } else if (
          (task.type == 'sensorDepth' || task.type == 'waterSampler') &&
          !task.args.sensorDepth
        ) {
          reject(
            new ValidationError(
              'Arm depth is required if the task is SWANARM or WATERSAMPLER'
            )
          )
        } else if (
          (task.type == 'stationKeep' || task.type == 'survey') &&
          !task.args.duration
        ) {
          reject(
            new ValidationError(
              'Duration is required if the task is STATIONKEEP or AUTO'
            )
          )
        } else if (task.type == 'survey' && !task.args.aoi) {
          reject(new ValidationError('Area is required if the task is AUTO'))
        }
      })
    }

    resolve(true)
  })
}

/**
 * Adds a new mission.
 **/
export async function addMission(
  mission: NewMissionType
): Promise<MissionType['id']> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  try {
    await validateMission(mission)
  } catch (err) {
    return Promise.reject(err)
  }

  let req = new PutMissionReq()
  req.name = mission.name
  req.areaId = mission.areaId
  req.geofenceId = mission.geofenceId
  req.nominalSpeed = mission.nominalSpeed
  if (mission.pointRadius) req.pointRadius = mission.pointRadius
  req.missionTasks = invertMissionTasksCoordinates(mission.missionTasks)
  req.createdUserId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(Error('Failed to save mission'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(Error('Invalid inputs for saving mission'))
  return rsp.id
}

/**
 * Updates a mission. The mission name, nominal speed, geofence Id, the tasks and the related parameters can be updated. The tasks can be added/updated/deleted as part of updating a mission.
 */
export async function updateMission(
  id: MissionType['id'],
  mission: NewMissionType
): Promise<MissionType['id']> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) return Promise.reject(new ValidationError('Mission ID is required'))
  try {
    await validateMission(mission)
  } catch (err) {
    return Promise.reject(err)
  }

  let req = new PutMissionReq()
  req.id = id
  req.name = mission.name
  req.areaId = mission.areaId
  req.geofenceId = mission.geofenceId
  req.nominalSpeed = mission.nominalSpeed
  if (mission.pointRadius) req.pointRadius = mission.pointRadius
  req.missionTasks = invertMissionTasksCoordinates(mission.missionTasks)
  req.createdUserId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(Error('Failed to save mission'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(Error('Invalid inputs for saving mission'))
  return rsp.id
}

/**
 * Deletes the mission.
 */
export async function deleteMission(id: number[]): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) return Promise.reject(new ValidationError('Mission ID is required'))
  let req = new RemoveMissionReq()
  req.missionIds = id
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Delete mission failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(Error('Invalid inputs for deleting mission'))
  else return true
}

/**
 * Fetches the list of survey types.
 * @return Returns a Promise which resolves to the list of survey types.
 * @example
  Types= ['survey', 'survey-1', 'survey-2']
  }
 */
export const getSurveyTypeList = async (): Promise<string[]> => {
  const req = new GetSurveyTypeReq()
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('GetSurveyTypeReq failed'))
  return rsp.types
}

/**
 * Fetches the given number of missions that are executed recently by the user. This method can be used to display the latest missions run by the user in the Dashboard.
 * @param runCount - The number of missions to be fetched.
 * @return Returns a Promise which resolves to an array of Mission objects if successful, else rejects with an Error.
 */
export async function getLatestMissions(
  missionCount: number
): Promise<LatestMission[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!missionCount) {
    return Promise.reject(
      new ValidationError('The count of missions is required')
    )
  }
  let req = new GetMissionReq()
  req.latestCount = missionCount
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetMissionRunReq failed'))
  } else if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  } else if (rsp && rsp instanceof GetMissionRsp) {
    return rsp.missions.map(
      (m: any): LatestMission => {
        return {
          id: m.id,
          name: m.name,
          areaId: m.areaId,
          lastMissionRun: m.lastMissionRun,
        }
      }
    )
  } else {
    return Promise.reject(new ValidationError('GetMissionReq unhandled error'))
  }
}
