import { GetRoleReq } from './messages'
import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import { Role } from './types'
import { ValidationError } from './error-types'

/**
 * Fetches the list of roles.
 * @return Returns a Promise which resolves to the list of roles.
 * @example
	Roles= ['ANALYSER','CONTROLLER','OPERATOR','SYSTEM ADMINISTRATOR']
	}
 */
export const getRoleList = async (): Promise<Role[]> => {
  const req = new GetRoleReq()
  req.recipient = gw.agent('admin')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('GetRoleReq failed'))
  return rsp.roles
}
