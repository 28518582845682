import { Geofence, GeofenceUpdate } from './types'
import { GetGeofenceReq, PutGeofenceReq, ImportGeofenceReq } from './messages'
import { astrid, gw } from './gateway'
import { haveIntersection, invertCoordinates } from './utils'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import { getUserId, getUserRole, isAuthenticated } from './common'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'

const invertGeofenceCoordinates = <T extends GeofenceUpdate>(
  geofence: T
): T => ({
  ...geofence,
  boundaries: geofence.boundaries
    .filter((boundary) => boundary.polygon)
    .map((boundary) => ({
      ...boundary,
      polygon: boundary.polygon.map(invertCoordinates),
    })),
})

/**
 * Fetches all the geofences.
 * @return Returns a Promise which resolves to an array of Geofence objects if successful, else rejects with an Error.
 */
export const getAllGeofences = async (): Promise<Geofence[]> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new GetGeofenceReq()
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  )
    return Promise.reject(new ValidationError('GetGeofenceReq failed'))
  return rsp.geofences.map(entity2geofence)
}

/**
 * Adds a new geofence.
 * @return Returns a Promise which resolves to the `id` of the new geofence if successful, else rejects with an Error.
 */
export async function addGeofence(geofence: GeofenceUpdate): Promise<number> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  geofence = invertGeofenceCoordinates(geofence)
  if (!geofence.name) {
    return Promise.reject(
      new ValidationError('Please provide a name for the geofence.')
    )
  }
  if (!geofence.areaId) {
    return Promise.reject(
      new ValidationError('Please provide the area for the geofence.')
    )
  }
  if (!geofence.boundaries) {
    return Promise.reject(
      new ValidationError('Atleast one boundary is required.')
    )
  }
  let bndrys = Object.entries(geofence.boundaries)
  if (!bndrys.length) {
    return Promise.reject(
      new ValidationError('Atleast one boundary is required.')
    )
  }
  for (const [key, value] of bndrys) {
    if (!value.id) {
      return Promise.reject(
        new ValidationError('Please enter valid id for the boundary.')
      )
    }
    if (!value.name) {
      return Promise.reject(
        new ValidationError('Please enter valid name for the boundary.')
      )
    }
    if (!value.polygon || (value.polygon && !value.polygon.length)) {
      return Promise.reject(
        new ValidationError('Please enter valid coordinates for polygon.')
      )
    }
  }
  const req = new PutGeofenceReq()
  req.name = geofence.name
  req.areaId = geofence.areaId
  req.boundaries = geofence.boundaries
  req.createdUserId = getUserId()
  req.operation = 'INSERT'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Failed to add geofence'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Invalid inputs for adding geofence')
    )
  return rsp.id
}

/**
 * Updates the geofence. The geofence name, the list of polygons and the polygon names can be updated.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function updateGeofence(
  geofence: GeofenceUpdate
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  geofence = invertGeofenceCoordinates(geofence)
  if (!geofence.id)
    return Promise.reject(new ValidationError('Geofence ID is required.'))
  if (geofence.boundaries) {
    let bndrys = Object.entries(geofence.boundaries)
    if (!bndrys.length) {
      return Promise.reject(
        new ValidationError('Atleast one boundary is required.')
      )
    }
    for (const [key, value] of bndrys) {
      if (!value.id) {
        return Promise.reject(
          new ValidationError('Please enter valid id for the boundary.')
        )
      }
      if (!value.name) {
        return Promise.reject(
          new ValidationError('Please enter valid name for the boundary.')
        )
      }
      if (!value.polygon || (value.polygon && !value.polygon.length)) {
        return Promise.reject(
          new ValidationError('Please enter valid coordinates for polygon.')
        )
      }
    }
  }
  const req = new PutGeofenceReq()
  req.id = geofence.id
  req.name = geofence.name
  req.boundaries = geofence.boundaries
  req.modifiedUserId = getUserId()
  req.operation = 'UPDATE'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Failed to update geofence'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Invalid inputs for updating geofence')
    )
  return true
}

/**
 * Deletes the geofence.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function deleteGeofence(id: Geofence['id']): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id)
    return Promise.reject(new ValidationError('Geofence ID is required.'))
  const req = new PutGeofenceReq()
  req.id = id
  req.operation = 'DELETE'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Delete geofence failed'))
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new ValidationError('Invalid geofence Id'))
  }
  return true
}

/**
 * Fetches the geofence for a given Id.
 * @param id - geofence Id.
 * @return Returns a Promise which resolves to the Geofence object if successful, else rejects with an Error.
 */
export async function getGeofence(id: Geofence['id']): Promise<Geofence> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id)
    return Promise.reject(new ValidationError('Geofence ID is required.'))

  const req = new GetGeofenceReq()
  req.id = id
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || !rsp.geofences || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('Failed to get geofence'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new ValidationError('Invalid geofence Id'))
  }
  return entity2geofence(rsp.geofences[0])
}

/**
 Import a KML based geofence.
 * @param file - geofence file in .kml format
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export function importGeofence(
  file: File,
  areaId: number,
  geofenceName: string
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!isAuthenticated()) {
      return Promise.reject(new AuthenticationError('Authentication failed.'))
    }
    let userRole = getUserRole()
    if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
      return Promise.reject(
        new AuthorizationError('User is not authorized for this operation.')
      )
    }
    if (!file)
      return Promise.reject(new ValidationError('Input file is required.'))
    let imp = new ImportGeofenceReq()
    var reader = new FileReader()
    reader.onload = async function (event: any) {
      let result = await extractCoords(event.target.result)
      imp.recipient = gw.agent('astrid')
      imp.filename = 'kml/' + file.name
      imp.areaId = areaId
      imp.geofenceName = geofenceName
      imp.contents = result
      imp.createdUserId = getUserId()
      let rsp = await gw.request(imp, REQ_TIMEOUT)
      if (
        !rsp ||
        rsp.perf == Performative.FAILURE ||
        rsp.perf == Performative.REFUSE
      ) {
        return Promise.reject('Upload of KML file failed.')
      }
      resolve(rsp.id)
    }
    reader.readAsText(file)
  })
}

const entity2geofence = (entity: any): Geofence =>
  invertGeofenceCoordinates({
    id: entity.id,
    name: entity.name,
    areaId: entity.areaId,
    boundaries: JSON.parse(entity.boundaries),
    createdUserId: entity.createdUserId,
    createdUser: entity.createdUser,
  })

async function extractCoords(plainText: any) {
  let parser = new DOMParser()
  let xmlDoc = parser.parseFromString(plainText, 'text/xml')
  let polygons = []

  if (xmlDoc.documentElement.nodeName == 'kml') {
    for (const item of xmlDoc.getElementsByTagName('Placemark') as any) {
      let placeMarkName = item
        .getElementsByTagName('name')[0]
        .childNodes[0].nodeValue.trim()
      let pgItems = item.getElementsByTagName('Polygon')

      /** POLYGONS PARSE **/
      for (const pg of pgItems) {
        let coords = pg
          .getElementsByTagName('coordinates')[0]
          .childNodes[0].nodeValue.trim()
        let points = coords.split(' ')

        for (const point of points) {
          if (!point) continue
          let coord = point.split(',')
          polygons.push([coord[1], coord[0]])
        }
      }
    }
  }
  return polygons
}
