import { Performative } from './fjage'
import { gw, astrid } from './gateway'
import { GetAreaReq, PutAreaReq } from './messages'
import { Area, AreaAdd } from './types'
import { REQ_TIMEOUT } from './timeout'
import { invertCoordinates, haveIntersection } from './utils'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { getUserId, isAuthenticated, getUserRole } from './common'

const invertAreaCoordinates = (area: Area): Area => ({
  ...area,
  coordinates: invertCoordinates(area.coordinates),
})

/**
 * Fetches the list of areas.
 * @return Returns a Promise which resolves to the list of Area objects.
 */
export const getAreaList = async (): Promise<Area[]> => {
  const req = new GetAreaReq()
  req.recipient = astrid
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('GetEntityReq(Area) failed'))
  return rsp.areas.map(invertAreaCoordinates)
}

/**
 * Update the area details.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function updateArea(area: Area): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new PutAreaReq()
  req.id = area.id
  req.name = area.name ? area.name : null
  req.geofenceId = area.geofenceId ? area.geofenceId : null
  req.originLat = area.coordinates ? area.coordinates[1] : null
  req.originLon = area.coordinates ? area.coordinates[0] : null
  req.colorCode = area.colorCode ? area.colorCode : null
  req.depth = area.depth ? area.depth : null
  req.userId = getUserId()
  req.operation = 'UPDATE'
  req.recipient = astrid
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutAreaReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if inputs are valid')
    )
  return true
}

/**
 * Adds a new area.
 * @return Returns a Promise which resolves to the `id` of the new area if successful, else rejects with an Error.
 */
export async function addArea(area: AreaAdd): Promise<number> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new PutAreaReq()
  req.name = area.name
  req.originLat = area.coordinates ? area.coordinates[1] : null
  req.originLon = area.coordinates ? area.coordinates[0] : null
  req.colorCode = area.colorCode
  req.depth = area.depth
  req.userId = getUserId()
  req.operation = 'INSERT'
  req.recipient = astrid
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutAreaReq failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if inputs are valid')
    )
  return rsp.id
}

/**
 * Deletes the area.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function deleteArea(id: Area['id']): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) return Promise.reject(new ValidationError('Area ID is required.'))
  const req = new PutAreaReq()
  req.id = id
  req.operation = 'DELETE'
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('Delete area failed'))
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new ValidationError('Invalid area Id'))
  }
  return true
}
