import * as turf from '@turf/turf'

import { Coordinates, MissionTask } from './types'

export type MissionTaskLawnmower = Extract<MissionTask, { type: 'lawnmower' }>

const FALLBACK_SPACING = 1
const FALLBACK_LEG = 1

export const getLawnmowerPolygon = (
  startingLocation: Coordinates,
  task: MissionTaskLawnmower
): [Coordinates, Coordinates, Coordinates, Coordinates] => {
  const lawnmowerLengthInKm = task.args.leg / 1000
  const lawnmowerWidthInKm = ((task.args.legs - 1) * task.args.spacing) / 1000

  const pointBottomLeft = turf.point(startingLocation)
  const pointTopLeft = turf.destination(
    pointBottomLeft,
    lawnmowerLengthInKm,
    task.args.bearing
  )
  const pointTopRight = turf.destination(
    pointTopLeft,
    lawnmowerWidthInKm,
    task.args.bearing + 90
  )
  const pointBottomRight = turf.destination(
    pointTopRight,
    lawnmowerLengthInKm,
    task.args.bearing + 180
  )

  return [
    pointBottomLeft.geometry.coordinates as Coordinates,
    pointTopLeft.geometry.coordinates as Coordinates,
    pointTopRight.geometry.coordinates as Coordinates,
    pointBottomRight.geometry.coordinates as Coordinates,
  ]
}

export const getLawnmowerBearing = ({
  startLocation,
  endLocation,
  length,
  width,
}: {
  startLocation: Coordinates
  endLocation: Coordinates
  length: number
  width: number
}): number => {
  const totalBearing = turf.bearing(startLocation, endLocation, { final: true })

  return normalizeAngle(
    totalBearing - calcTriangleAngle(length, width) + (length < 0 ? 180 : 0)
  )
}

const calcTriangleAngle = (length: number, width: number): number => {
  return length === 0 ? 0 : turf.radiansToDegrees(Math.atan(width / length))
}

export const getLawnmowerSpacing = ({
  startLocation,
  endLocation,
  legs,
  bearing,
}: {
  startLocation: Coordinates
  endLocation: Coordinates
  legs: number
  bearing: number
}): number => {
  const distance = getDistanceOnGuideLine({
    startLocation,
    endLocation,
    guideBearing: bearing + 90,
  })

  return legs > 1
    ? Math.round(distance / (legs - 1)) || FALLBACK_SPACING
    : FALLBACK_SPACING
}

export const getLawnmowerLeg = ({
  startLocation,
  endLocation,
  bearing,
}: {
  startLocation: Coordinates
  endLocation: Coordinates
  bearing: number
}): number => {
  return (
    Math.round(
      getDistanceOnGuideLine({
        startLocation,
        endLocation,
        guideBearing: bearing,
      })
    ) || FALLBACK_LEG
  )
}

// Gets distance from `startLocation` to closest from `endLocation` point on guideline going from `startLocation` with provided bearing
const getDistanceOnGuideLine = ({
  startLocation,
  endLocation,
  guideBearing,
}: {
  startLocation: Coordinates
  endLocation: Coordinates
  guideBearing: number
}): number => {
  const maxDistance = turf.distance(startLocation, endLocation)
  const guideLine = turf.lineString([
    turf.destination(startLocation, maxDistance, guideBearing).geometry
      .coordinates,
    turf.destination(startLocation, maxDistance, guideBearing + 180).geometry
      .coordinates,
  ])

  const pointOnGuideLine = turf.nearestPointOnLine(guideLine, endLocation)
  const distanceOnGuideLine = turf.distance(startLocation, pointOnGuideLine, {
    units: 'meters',
  })

  const angle = turf.bearing(startLocation, pointOnGuideLine, { final: true })
  const isPositiveAngle = isAngleInRange({
    angle,
    start: guideBearing - 90,
    end: guideBearing + 90,
  })

  return isPositiveAngle ? distanceOnGuideLine : -1 * distanceOnGuideLine
}

export const isAngleInRange = ({
  angle,
  start,
  end,
}: {
  angle: number
  start: number
  end: number
}): boolean => {
  const normalizedStart = normalizeAngle(start)
  const normalizedEnd = normalizeAngle(end)

  if (normalizedStart > normalizedEnd) {
    return angle <= normalizedEnd || normalizedStart <= angle
  } else {
    return normalizedStart <= angle && angle <= normalizedEnd
  }
}

const normalizeAngle = (angle: number): number => {
  const positiveAngle = angle < 0 ? 360 + angle : angle
  return positiveAngle % 360
}
