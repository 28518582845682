import { MessageClass } from './fjage'

/* Swancloud */
export const GetAreaReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetAreaReq'
)
export const PutAreaReq = MessageClass(
  'org.subnero.swancloud.web.ui.PutAreaReq'
)
export const GetGeofenceReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetGeofenceReq'
)
export const PutGeofenceReq = MessageClass(
  'org.subnero.swancloud.web.ui.PutGeofenceReq'
)
export const ImportGeofenceReq = MessageClass(
  'org.subnero.swancloud.web.ui.ImportGeofenceReq'
)
export const GetTaskReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetTaskReq'
)
export const GetMissionReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionReq'
)
export const GetMissionRsp = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionRsp'
)
export const PutMissionReq = MessageClass(
  'org.subnero.swancloud.web.ui.PutMissionReq'
)
export const RemoveMissionReq = MessageClass(
  'org.subnero.swancloud.web.ui.RemoveMissionReq'
)
export const GetMissionRunReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionRunReq'
)
export const GetMissionRunRsp = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionRunRsp'
)
export const GetMissionRunLocReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionRunLocReq'
)
export const GetMissionRunLogReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionRunLogReq'
)
export const GetMissionTaskStatusReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetMissionTaskStatusReq'
)
export const PutAlertSettingReq = MessageClass(
  'org.subnero.swancloud.web.ui.PutAlertSettingReq'
)
export const GetAlertSettingReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetAlertSettingReq'
)
export const GetNotificationReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetNotificationReq'
)
export const GetSwanBotReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSwanBotReq'
)
export const GetActiveSwanBotReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetActiveSwanBotReq'
)
export const GetSwanBotIconReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSwanBotIconReq'
)
export const GetSwanBotForUserReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSwanBotForUserReq'
)
export const PutSwanBotReq = MessageClass(
  'org.subnero.swancloud.web.ui.PutSwanBotReq'
)
export const GetLocationDataReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetLocationDataReq'
)
export const GetDataSummaryReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetDataSummaryReq'
)
export const GetImageReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetImageReq'
)
export const GetSensorDataReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSensorDataReq'
)
export const GetSensorDataRangeReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSensorDataRangeReq'
)
export const GetLatestSensorDataReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetLatestSensorDataReq'
)
export const GetSensorParamReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSensorParamReq'
)
export const PutOperationRsp = MessageClass(
  'org.subnero.swancloud.web.ui.PutOperationRsp'
)
export const GetSurveyTypeReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetSurveyTypeReq'
)
export const InterpolateReq = MessageClass(
  'org.subnero.swancloud.web.ui.InterpolateReq'
)
export const GetInterpolationInfoReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetInterpolationInfoReq'
)
export const GetInterpolatedDataReq = MessageClass(
  'org.subnero.swancloud.web.ui.GetInterpolatedDataReq'
)
export const AbortInterpolationReq = MessageClass(
  'org.subnero.swancloud.web.ui.AbortInterpolationReq'
)

export const LoginReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.LoginReq'
)
export const GetRoleReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.GetRoleReq'
)
export const GetUserReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.GetUserReq'
)
export const PutUserReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.PutUserReq'
)
export const PutPasswordReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.PutPasswordReq'
)
export const PutForgotPasswordReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.PutForgotPasswordReq'
)
export const VerifyForgotPwdReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.VerifyForgotPwdReq'
)
export const GetQRCodeReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.GetQRCodeReq'
)
export const Put2FAReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.Put2FAReq'
)
export const Remove2FAReq = MessageClass(
  'org.subnero.swancloud.web.useradmin.Remove2FAReq'
)
export const MissionControlReq = MessageClass(
  'org.subnero.swancloud.web.bot.MissionControlReq'
)
export const MissionCtrlFailureNtf = MessageClass(
  'org.subnero.swancloud.web.bot.MissionCtrlFailureNtf'
)
export const AbortToHomeReq = MessageClass(
  'org.subnero.swancloud.web.bot.AbortToHomeReq'
)
export const GetSensorClassesReq = MessageClass(
  'org.arl.unet.c3.GetSensorClassesReq'
)
export const GetSensorClassMetadataReq = MessageClass(
  'org.arl.unet.c3.GetSensorClassMetadataReq'
)

/* AMRI */
export const BackseatDriverRFP = MessageClass(
  'org.arl.amri.bsd.BackseatDriverRFP'
)
export const MissionStatusNtf = MessageClass(
  'org.arl.amri.captain.MissionStatusNtf'
)
export const BackseatDriverProposal = MessageClass(
  'org.arl.amri.bsd.BackseatDriverProposal'
)

/* Fjage */
export const GenericMessage = MessageClass('org.arl.fjage.GenericMessage')
export const ShellExecReq = MessageClass('org.arl.fjage.shell.ShellExecReq')
export const GetFileReq = MessageClass('org.arl.fjage.shell.GetFileReq')
export const PutFileReq = MessageClass('org.arl.fjage.shell.PutFileReq')
