import { gw } from './gateway'
import {
  MissionType,
  MissionRunLocInfo,
  DataVisFilter,
  MissionRun,
  MissionRunEssential,
} from './types'
import {
  GetMissionRunReq,
  GetMissionRunRsp,
  GetMissionRunLocReq,
} from './messages'
import { Performative } from './fjage'
import { REQ_TIMEOUT } from './timeout'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'
import { haveIntersection } from './utils'

/**
 * Fetches the list of mission runs and the sensor parameters for the given criteria. In the SwanViz, this method can be used to fetch the list of mission runs and sensor parameters for the filter criteria in the Data Visualization.
 * @param filter - criteria for fetching the mission runs.
 * @return Returns a Promise which resolves to an array of MissionRun objects if successful, else rejects with an Error.
 */
export async function getMissionRuns(
  filter: DataVisFilter
): Promise<MissionRun[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let missionRuns: MissionRun[] = []
  if (!filter.fromDate || !filter.toDate || !filter.areaId)
    return Promise.reject(
      new ValidationError('From date, To date and Area Id is mandatory')
    )
  let req = new GetMissionRunReq()
  req.fromDate = filter.fromDate
  req.toDate = filter.toDate
  req.areaId = filter.areaId
  req.missionId = filter.missionId
  req.swanbotId = filter.swanbotId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  )
    return Promise.reject(
      new ValidationError('Request failed: GetMissionRunReq(filter)')
    )
  if (rsp && !rsp.missionRuns)
    return Promise.reject(
      new ValidationError('No mission runs found for the given criteria')
    )

  return rsp.missionRuns.map(
    (r: any): MissionRun => {
      return {
        runId: r.id,
        runDate: r.runDate,
        missionId: r.missionId,
        missionName: r.missionName,
        swanbotId: r.swanbotId,
        swanbotName: r.swanbotName,
        status: r.status,
        sensorIds: r.sensors,
        areaId: r.areaId,
      }
    }
  )
}

/**
 * Fetches the full actual path for the given mission run. In the SwanViz, this method can be used to fetch the SwanBot path from the start of the mission run in case of page refresh or user switches menu and returns to view the mission progress. This can also be used to display the path of the selected mission run, when `SwanBots path` checkbox is checked in the mission cards in Data Visualization.
 * @return Returns a Promise which resolves to an array of MissionRunLocInfo objects if successful, else rejects with an Error.
 */
export async function getActualPath(
  runId: number
): Promise<MissionRunLocInfo[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!runId)
    return Promise.reject(new ValidationError('Mission run ID is required'))
  let req = new GetMissionRunLocReq()
  req.runId = runId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  ) {
    return Promise.reject(
      new ValidationError(
        'Request failed: GetMissionRunLocReq(runId,lastUpdated)'
      )
    )
  }

  if (rsp && !rsp.locInfo) return []

  const result = (rsp.locInfo as MissionRunLocInfo[]).map(
    (r: any): MissionRunLocInfo => ({
      swanbotId: r.swanbotId,
      location: [r.locLon, r.locLat],
      heading: r.heading,
      locTime: r.timeAtLocation,
    })
  )

  return result.sort((a, b) => a.locTime - b.locTime)
}

/**
 * Fetches the location details for the given mission run from the last updated time. This method can be used to update the path of the SwanBot during a mission run.
 * @param lastUpdated - The time from which the location details are to be fetched (in milliseconds).
 * @return Returns a Promise which resolves to an array of MissionRunLocInfo objects if successful, else rejects with an Error.
 */
export async function getMissionRunLocInfo(
  runId: number,
  lastUpdated: number
): Promise<MissionRunLocInfo[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!runId)
    return Promise.reject(new ValidationError('Mission run ID is required'))
  if (typeof lastUpdated !== 'number')
    return Promise.reject(new ValidationError('Last updated time is required'))
  let req = new GetMissionRunLocReq()
  req.runId = runId
  req.lastUpdated = lastUpdated
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError(
        'Request failed: GetMissionRunLocReq(runId,lastUpdated)'
      )
    )
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }

  if (rsp && !rsp.locInfo) return []

  return rsp.locInfo.map(
    (r: any): MissionRunLocInfo => ({
      swanbotId: r.swanbotId,
      location: [r.locLon, r.locLat],
      heading: r.heading,
      locTime: r.timeAtLocation,
    })
  )
}

/**
 * Fetches the mission run details for a given run ID. In the SwanViz, this is intended to fetch the mission run details for the current run ID linked to the selected mission in the mission edit/execution page.
 * @param id - mission run Id.
 * @return Returns a Promise which resolves to the Mission object if successful, else rejects with an Error.
 */
export async function getMissionRun(id: number): Promise<MissionRunEssential> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) {
    return Promise.reject(new ValidationError('Mission Run ID is required'))
  }
  let req = new GetMissionRunReq()
  req.runId = id
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetMissionRunReq failed'))
  } else if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  } else if (rsp && rsp instanceof GetMissionRunRsp) {
    let data = {
      runId: id,
      runDate: rsp.missionRuns[0].runDate,
      missionId: rsp.missionRuns[0].missionId,
      swanbotId: rsp.missionRuns[0].swanbotId,
      status: rsp.missionRuns[0].status,
      areaId: rsp.missionRuns[0].areaId,
    }
    return data
  } else {
    return Promise.reject(
      new ValidationError('GetMissionRunReq unhandled error')
    )
  }
}
