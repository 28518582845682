import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { AuthenticationError } from './error-types'
import { User } from './types'

export type UserSession = Pick<User, 'id' | 'roles'>

const CURRENT_USER_LOCALSTORAGE_KEY = 'currentUser'

export const debug =
  (typeof process != 'undefined' && process.env.DEBUG) || false

export function saveUserSession(user: UserSession) {
  try {
    window.localStorage.setItem(
      CURRENT_USER_LOCALSTORAGE_KEY,
      JSON.stringify(user)
    )
  } catch (e) {
    alert(
      'Unable to save session information. You probably have cookies or localstorage disabled in your browser. Please enable them to continue using this app.'
    )
    console.error(e)
  }
}

export function getUserSession(): UserSession | undefined {
  try {
    const userFromLocalStorage = window.localStorage.getItem(
      CURRENT_USER_LOCALSTORAGE_KEY
    )
    if (userFromLocalStorage) {
      return JSON.parse(userFromLocalStorage)
    }
  } catch (e) {
    console.error(e)
  }
  return undefined
}

export function removeUserSession() {
  try {
    window.localStorage.removeItem(CURRENT_USER_LOCALSTORAGE_KEY)
  } catch (e) {
    console.error(e)
  }
}

export function isAuthenticated() {
  return !!getUserSession()
}

export function getUserRole() {
  const user = getUserSession()
  if (!user) {
    throw new AuthenticationError('User is not authorized for this operation.')
  }
  return user.roles
}

export function getUserId() {
  const user = getUserSession()
  if (!user) {
    throw new AuthenticationError('Authentication failed')
  }
  return user.id
}
