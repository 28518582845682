import {
  GetSensorParamReq,
  GetDataSummaryReq,
  GetSensorClassesReq,
  GetSensorClassMetadataReq,
} from './messages'
import { gw } from './gateway'
import { DataSummary, SensorParameter } from './types'
import { Performative } from './fjage'
import { REQ_TIMEOUT } from './timeout'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, debug } from './common'
import { haveIntersection } from './utils'

/**
 * Fetches the sensor parameter details for a given Id.
 * @param id - sensor parameter Id.
 * @return Returns a Promise which resolves to the SensorParameter object if successful, else rejects with an Error.
 */
export async function getSensorParameter(id: number): Promise<SensorParameter> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) return Promise.reject(new ValidationError('Sensor ID is required'))
  const req = new GetSensorParamReq()
  req.id = id
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: GetSensorParamReq')
    )
  }
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }
  return rsp.sensorParams[0]
}

/**
 * Fetches all the sensor parameters and the related details.
 * @return Returns a Promise which resolves to an array of SensorParameter objects if successful, else rejects with an Error.
 */
export async function getAllSensorParameters(): Promise<SensorParameter[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new GetSensorParamReq()
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: GetSensorParamReq')
    )
  }

  let scReq = new GetSensorClassesReq()
  scReq.recipient = gw.agent('data')
  let scRsp = await gw.request(scReq, REQ_TIMEOUT)
  if (debug) console.log('SensorClasses: ' + JSON.stringify(scRsp))
  if (!scRsp || (scRsp && scRsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: GetSensorParamReq')
    )
  }
  var spArray = []
  for (let s of scRsp.sensorClasses) {
    let mdReq = new GetSensorClassMetadataReq()
    mdReq.sensorClass = s
    mdReq.recipient = gw.agent('data')
    let mdRsp = await gw.request(mdReq, REQ_TIMEOUT)
    if (!mdRsp)
      return Promise.reject(
        new ValidationError('Request failed: GetSensorParamReq')
      )
    let keys = Object.keys(mdRsp.metadata)
    for (let key of keys) {
      let sp = rsp.sensorParams.find(
        (p: SensorParameter) =>
          p.sensorClass.substring(p.sensorClass.indexOf(':') + 1) == key
      )
      if (!sp) continue
      let metadata = {
        id: sp.id,
        name: key,
        description: key,
        units: mdRsp.metadata[key].units,
        min: mdRsp.metadata[key].minimum,
        max: mdRsp.metadata[key].maximum,
      }
      spArray.push(metadata)
    }
    if (debug) console.log('Metadata-1: ' + JSON.stringify(spArray))
  }

  return (spArray as SensorParameter[]).filter(
    (param) => param.name !== 'image'
  )
}

/**
 * This method fetches the sensor data for the current run ID of the SwanBot ID
 * to be displayed in the plots in the `Sensors` tab of the SwanBot info card.
 * The time range for the data fetched will be decided in the backend API of `GetSummaryDataReq`.
 * @param swanbotId - The SwanBot ID for which the data summary is required.
 * @return Returns a Promise which resolves to an array of DataSummary objects if successful, else rejects with an Error.
 */
export async function getDataSummary(
  swanbotId: number
): Promise<DataSummary[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new GetDataSummaryReq()
  req.swanbotId = swanbotId
  req.recipient = gw.agent('astrid')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: GetDataSummaryReq')
    )
  }
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }
  return rsp.dataSummary
}
