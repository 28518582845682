import { Performative } from './fjage'
import { gw } from './gateway'
import { MissionNotification, Notification, NotificationFilter } from './types'
import { GetNotificationReq, GetMissionRunLogReq } from './messages'
import { REQ_TIMEOUT } from './timeout'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'
import { haveIntersection } from './utils'

const MISSION_NTF_SEVERITY = 2
const MISSION_NTF_LIFETIME = 10
const SWANBOT_NTF_SEVERITY = 1
const SWANBOT_NTF_LIFETIME = 60
const SENSORDATA_NTF_SEVERITY = 1
const SENSORDATA_NTF_LIFETIME = 30

/**
 * Fetches the notifications for the User threshold settings, SwanBot and Mission for the current Mission run.
 * @param runId - The runId of the current Mission.
 * @param [ntfCount] - The number of notifications to be fetched. If the `ntfCount` is passed as negative then the last n notifications are fetched, where n = ntfCount. If the `ntfCount` is positive, the given number of notifications are fetched from the lastUpdatedTime.
 * @param [lastUpdatedTime] - The timestamp from when the previous set of notifications where fetched. This is intended for fetching notifications in order, from the starting of the mission.
 * @return Returns a Promise which resolves to an array of Notification objects if successful, else rejects with an Error.
 */
export async function getMissionRunNtf(
  runId: number,
  ntfCount?: number,
  lastUpdatedTime?: number
): Promise<MissionNotification[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  if (!runId) {
    return Promise.reject(new ValidationError('Mission run ID is required'))
  }

  let req = new GetMissionRunLogReq()
  req.runId = runId
  req.ntfCount = ntfCount
  req.lastUpdatedTime = lastUpdatedTime
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('getMissionRunNtf Request failed')
    )
  }

  if (rsp && !rsp.missionLog) return []

  return (rsp.missionLog as MissionNotification[])
    .map((log) => {
      if (log.type == 'MISSION') {
        log.severity = MISSION_NTF_SEVERITY
        log.lifetime = MISSION_NTF_LIFETIME
      } else if (log.type == 'SWANBOT') {
        log.severity = SWANBOT_NTF_SEVERITY
        log.lifetime = SWANBOT_NTF_LIFETIME
      } else if (log.type == 'SENSOR DATA') {
        log.severity = SENSORDATA_NTF_SEVERITY
        log.lifetime = SENSORDATA_NTF_LIFETIME
      }

      return log
    })
    .sort((a, b) => b.ntfTime - a.ntfTime)
}

/**
 * Fetches all the notifications for the User threshold settings, SwanBot and Mission. In the SwanViz, this method can be used to display the details in the `Alerts and Notifications` menu tab.
 * @param pageNo - The selected page number from the pagination list.
 */
export async function getAllNotifications(
  pageNo: number
): Promise<{
  ntfCount: number
  pageCount: number
  notifications: Notification[]
}> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!pageNo) pageNo = 1
  let req = new GetNotificationReq()
  req.pageNo = pageNo
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError(
        'Failed to get the notifications for the page ' + pageNo
      )
    )
  return {
    ntfCount: rsp.noOfNtfs,
    pageCount: rsp.pageCount,
    notifications: rsp.notifications,
  }
}

/**
 * Fetches all the notifications for the User threshold settings, SwanBot and Mission for the given area, type and SwanBot ID.
 * @param filter - The filter criteria for fetching notifications.
 */
export async function getFilteredNtfs(
  filter: NotificationFilter
): Promise<{
  ntfCount: number
  pageCount: number
  notifications: Notification[]
}> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetNotificationReq()
  req.areaId = filter.areaId
  req.swanbotId = filter.swanbotId
  req.type = filter.type
  req.sortField = filter.sortField
  req.sortDirection = filter.sortDirection
  req.pageNo = filter.pageNo
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError(
        'Failed to get the notifications for the filter ' +
          JSON.stringify(filter)
      )
    )
  return {
    ntfCount: rsp.noOfNtfs,
    pageCount: rsp.pageCount,
    notifications: rsp.notifications,
  }
}
