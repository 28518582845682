import {
  GetLatestSensorDataReq,
  GetLocationDataReq,
  GetSensorDataRangeReq,
  GetSensorDataReq,
  GetInterpolationInfoReq,
  GetInterpolatedDataReq,
  AbortInterpolationReq,
  InterpolateReq,
} from './messages'
import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import {
  Coordinates,
  LatestSensorData,
  LocationData,
  MissionRunFilter,
  SensorData,
  Timestamp,
  InterpolationInputs,
  InterpolationInfo,
  IntplDataFilter,
  InterpolatedData,
} from './types'
import { relativeUrlToAbsolute, haveIntersection, imageSrc } from './utils'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'

const entityToSensorData = (entity: any): SensorData => {
  return {
    runId: entity.missionRunId,
    runDate: entity.runDate,
    missionId: entity.missionId,
    missionName: entity.missionName,
    swanbotName: entity.swanbotName,
    time: entity.dataTime,
    location: entity.dataLocation,
    sensorParamId: entity.sensorId,
    sensorParamName: entity.sensorName,
    sensorParamValue: entity.sensorValue,
  }
}

const entityToLatestData = (entity: any): LatestSensorData => {
  return {
    runId: entity.missionRunId,
    sensorParamId: entity.sensorId,
    sensorParamName: entity.sensorName,
    sensorParamValue: entity.sensorValue,
  }
}

const entityToLocationData = (entity: any): LocationData => {
  return {
    runId: entity.missionRunId,
    runDate: entity.runDate,
    missionId: entity.missionId,
    missionName: entity.missionName,
    swanbotName: entity.swanbotName,
    sensorParamId: entity.sensorId,
    sensorParamName: entity.sensorName,
    sensorParamValue:
      entity.sensorName === 'image'
        ? imageSrc(entity.imgBlob, entity.mimeType)
        : entity.sensorValue,
  }
}

const entityToIntplData = (entity: any): InterpolatedData => {
  return {
    time: entity.dataTime,
    location: entity.dataLocation,
    sensorParamName: entity.sensorName,
    sensorParamValue: entity.sensorValue,
  }
}

/**
 * Fetches the sensor data for the given run ID and list of sensor parameters..
 * @param filter - The list of mission run IDs and the corresponding sensor parameters.
 * @return Returns a Promise which resolves to an array of SensorData objects if successful, else rejects with an Error.
 */
export async function getSensorData(
  filter: MissionRunFilter[]
): Promise<SensorData[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetSensorDataReq()
  req.filter = filter
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: MissionRunListReq(filter)')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  if (rsp && !rsp.sensorData)
    return Promise.reject(
      new ValidationError('No data found for the given criteria')
    )
  return rsp.sensorData.map(entityToSensorData)
}

/**
 * Fetches the sensor data for the given MissionRunFilter and the given period.
 * @param filter - The list of mission run IDs and the selected sensor parameters of the mission runs.
 * @param fromTime - from time. The time will be in the format of unix epoch time in milliseconds(UTC).
 * @param toTime - from time. The time will be in the format of unix epoch time in milliseconds(UTC).
 * @return Returns a Promise which resolves to an array of SensorData objects if successful, else rejects with an Error.
 */
export async function getSensorDataRange(
  filter: MissionRunFilter[],
  fromTime: Timestamp,
  toTime: Timestamp
): Promise<SensorData[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!filter)
    return Promise.reject(
      new ValidationError(
        'Mission run ID and sensor parameter list is required'
      )
    )
  if (!fromTime)
    return Promise.reject(new ValidationError('From time is required'))
  if (!toTime) return Promise.reject(new ValidationError('To time is required'))
  let req = new GetSensorDataRangeReq()
  req.filter = filter
  req.fromTime = fromTime
  req.toTime = toTime
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: GetSensorDataRangeReq(filter)')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  if (rsp && !rsp.sensorData)
    return Promise.reject(
      new ValidationError('No data found for the given criteria')
    )
  return rsp.sensorData.map(entityToSensorData)
}

/**
 * Fetches the data for the given location, captured during the selected mission runs in Data Visualization. In the SwanViz, this method can be used to display the sensor data for the selected mission runs when hovered over a location in the Data Visualization tab. The sensor data returned includes images also as one of the sensor parameters.
 * In the SwanViz, this method can be used to display the sensor data and images for the selected mission runs
 * when hovered over a location in the Data Visualization tab.
 * @param location - The location for which the data has to be fetched in the format [longitude,latitude].
 * @param filter - The list of mission run IDs and the selected sensor parameters of the mission runs.
 * @return Returns a Promise which resolves to an array of LocationData objects if successful, else rejects with an Error.
 */
export async function getLocationData(
  location: Coordinates,
  filter: MissionRunFilter[]
): Promise<LocationData[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetLocationDataReq()
  req.filter = filter
  req.location = location
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: GetLocationDataReq(filter)')
    )
  }
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }
  return rsp.sensorData.map(entityToLocationData)
}

/**
 * Fetches the latest sensor data for the given run Id. In the SwanViz, this can be used to display the sensor values in the `Sensors` tab in the SwanBot info card. The sensor data returned does not include images.
 * @return Returns a Promise which resolves to array of LatestSensorData objects if successful, else rejects with an Error.
 */
export async function getLatestSensorData(
  swanbotId: number
): Promise<LatestSensorData[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetLatestSensorDataReq()
  req.swanbotId = swanbotId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: GetLatestSensorData')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return rsp.data.map(entityToLatestData)
}

/**
 * This method is invoked on click of `Export to CSV` in the Data Visualization tab, and fetches the sensor data for the selected run ID and list of sensor parameters, and downloads the data in csv format.
 * @param filter - The list of mission run IDs and the corresponding sensor parameters.
 */
export async function exportToCsv(
  filter: MissionRunFilter[]
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetSensorDataReq()
  req.filter = filter
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(
      new ValidationError('Request failed: MissionRunListReq(filter)')
    )
  }
  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }
  if (rsp && !rsp.sensorData)
    return Promise.reject(
      new ValidationError('No data found for the given criteria')
    )
  let rows: any[] = []
  rsp.sensorData.forEach((data: any) => {
    let subrow = []
    for (let i in data) {
      subrow.push(data[i])
    }
    rows.push(subrow)
  })

  let csvContent =
    'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n')
  const encodedUri = encodeURI(csvContent)
  window.open(encodedUri, '_self')

  return true
}

/**
 * Fetches the interpolation details including progress and status for the given inputs and user. In the SwanViz, this can be used to display the details in the `Interpolated data` section when the user clicks the `Interpolated data` link in the Data tab.
 * @return Returns a Promise which resolves to the `InterpolationInfo` object if successful, else rejects with an Error.
 */
export async function getInterpolationInfo(
  filter: InterpolationInputs
): Promise<InterpolationInfo | null> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetInterpolationInfoReq()
  req.userId = getUserId()
  req.areaId = filter.areaId
  req.missionRunIds = filter.missionRunIds
  req.sensorParam = filter.sensorParam
  req.minDepth = filter.minDepth
  req.maxDepth = filter.maxDepth
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: GetInterpolationInfoReq')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return rsp.info
    ? {
        id: rsp.info.id,
        userId: rsp.info.userId,
        progress: rsp.info.progress,
        status: rsp.info.status,
      }
    : null
}

/**
 * Starts interpolation for the selected filters in the Data tab. In the SwanViz, this method is called when the user clicks the `Interpolate` button in the `Interpolated data` page. The interpolation can be done only for a single sensor parameter at one time.
 * @return Returns a Promise which resolves to the true if successful, else rejects with an Error.
 */
export async function startInterpolation(
  filter: InterpolationInputs
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new InterpolateReq()
  req.userId = getUserId()
  req.areaId = filter.areaId
  req.missionName = filter.missionName
  req.swanbotName = filter.swanbotName
  req.startDate = filter.startDate
  req.endDate = filter.endDate
  req.missionRunIds = filter.missionRunIds
  req.sensorParam = filter.sensorParam
  req.minDepth = filter.minDepth
  req.maxDepth = filter.maxDepth
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: GetInterpolationInfoReq')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}

/**
 * Fetches the interpolated sensor data for the given run ID and sensor parameter.
 * @param filter - The list of mission run IDs and the selected sensor parameter in the Interpolated data tab.
 * @return Returns a Promise which resolves to an array of SensorData objects if successful, else rejects with an Error.
 */
export async function getInterpolatedData(
  filter: IntplDataFilter
): Promise<InterpolatedData[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new GetInterpolatedDataReq()
  req.areaId = filter.areaId
  req.missionRunIds = filter.missionRunIds
  req.sensorParam = filter.sensorParam
  req.minDepth = filter.minDepth
  req.maxDepth = filter.maxDepth
  req.userId = getUserId()
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: GetInterpolatedDataReq(filter)')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  if (rsp && !rsp.sensorData)
    return Promise.reject(
      new ValidationError('No data found for the given criteria')
    )
  return rsp.sensorData.map(entityToIntplData)
}

/**
 * Cancel the interpolation for the given inputs and user. In the SwanViz, this method is to be invoked when user clicks 'Cancel' in the Interolated data tab. The interpolation for the selected sensor parameter will be cancelled.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function cancelInterpolation(
  filter: InterpolationInputs
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let req = new AbortInterpolationReq()
  req.userId = getUserId()
  req.areaId = filter.areaId
  req.missionRunIds = filter.missionRunIds
  req.sensorParam = filter.sensorParam
  req.recipient = gw.agent('astrid')
  req.minDepth = filter.minDepth
  req.maxDepth = filter.maxDepth
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(
      new ValidationError('Request failed: AbortInterpolationReq')
    )
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}
