import { Gateway } from './fjage'
import { debug } from './common'
export const HOST =
  (typeof process != 'undefined' && process.env.REACT_APP_HOST_IP) ||
  'localhost'
export const PORT =
  (typeof process != 'undefined' && process.env.REACT_APP_HOST_PORT) || 8080
if (debug) console.log('HOST:' + HOST + '|PORT:' + PORT)
export const gw = new Gateway(HOST, PORT)
export const astrid = gw.agent('astrid')
gw.subscribe(gw.topic('org.arl.amri.Topics.BackseatDriverRFP'))
gw.subscribe(gw.topic('org.subnero.swanbrain.Topics.AstridNtf'))
