import { gw } from './gateway'
import { Performative } from './fjage'
import {
  MissionTask,
  Coordinates,
  MissionType,
  SwanBot,
  TaskStatus,
} from './types'
import {
  BackseatDriverRFP,
  GetMissionTaskStatusReq,
  ShellExecReq,
} from './messages'
import { REQ_TIMEOUT } from './timeout'
import { isAuthenticated, getUserRole, getUserId } from './common'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { haveIntersection } from './utils'

const DEFAULT_SD_TIME = 10

/**
 * Sets the properties estDuration, estDistance and waypoints of the MissionTask object if available.
 * @param args
 * @param args.nominalSpeed - The speed set at the mission level or task level.
 * @param args.currentLocation - The current location from where the distance or the waypoints have to be fetched.
 * @param args.origin - The coordinates of the area.
 * @return Returns a Promise which resolves to the MissionTask object if successful, else rejects with an Error. The MissionTask will be set with the `estDistance`, `estDuration` and `waypoints`.
 */
export async function getTaskEstimate({
  missionTask: initialMissionTask,
  nominalSpeed,
  currentLocation,
  origin,
}: {
  missionTask: MissionTask
  nominalSpeed: number
  currentLocation: Coordinates
  origin: Coordinates
}): Promise<MissionTask> {
  const missionTask = {
    ...initialMissionTask,
  }
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let rsp,
    noOfSpacing,
    totalDistance = 0,
    totalTime = 0

  if (missionTask.type == 'go') {
    const location =
      missionTask.args.location === 'home' ? null : missionTask.args.location
    let xy1 = await lnglat2xy(currentLocation, origin)
    let xy2 = location ? await lnglat2xy(location, origin) : null
    totalDistance = xy2 ? getDistanceFromLatLon(xy1, xy2) : 0
    totalTime = Math.round(totalDistance / nominalSpeed)
  } else if (missionTask.type == 'lawnmower') {
    let leg = missionTask.args.leg
    let spacing = missionTask.args.spacing
    let legs = missionTask.args.legs
    noOfSpacing = legs - 1
    totalDistance = Math.abs(leg) * legs + Math.abs(spacing) * noOfSpacing
    totalTime = Math.round(totalDistance / nominalSpeed)
    let rfp = new BackseatDriverRFP()
    rfp.type = missionTask.type
    let xy = await lnglat2xy(currentLocation, origin)
    rfp.location = xy
    rfp.kwargs = missionTask.args
    rfp.recipient = gw.topic('org.arl.amri.Topics.BackseatDriverRFP')
    rsp = await gw.request(rfp, REQ_TIMEOUT)
  } else if (
    missionTask.type == 'sensorDepth' ||
    missionTask.type == 'waterSampler'
  ) {
    totalDistance = 0
    let rfp = new BackseatDriverRFP()
    rfp.type = missionTask.type
    rfp.args = missionTask.args.sensorDepth
    rfp.recipient = gw.topic('org.arl.amri.Topics.BackseatDriverRFP')
    rsp = await gw.request(rfp, REQ_TIMEOUT)
    if (rsp && rsp.maxDuration) totalTime = rsp.maxDuration
    else totalTime = Math.round(missionTask.args.sensorDepth * DEFAULT_SD_TIME)
  }

  if (rsp && rsp.path && missionTask.type === 'lawnmower') {
    rsp.path.forEach((p: any) => {
      p.location = xy2lnglat(p.location, origin)
    })
    missionTask.waypoints = rsp.path
  }
  missionTask.estDistance = totalDistance
  missionTask.estDuration = totalTime
  return missionTask
}

/**
 * Fetches all the task details for the given mission run id.
 * @return Returns a Promise which resolves to an array of TaskStatus objects if successful, else rejects with an Error.
 */
export async function getAllTaskStatus(runId: number): Promise<TaskStatus[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!runId)
    return Promise.reject(new ValidationError('Mission run ID is required'))
  let req = new GetMissionTaskStatusReq()
  req.runId = runId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  ) {
    return Promise.reject(
      new ValidationError('Request failed: MissionTaskStatusReq(runId)')
    )
  }

  return rsp.taskList
}

/**
 * Fetches the task details for the given mission run id and task Id. This task provides the status of a specific task. For displaying status of the tasks during a mission run, it is recommended to use `getAllTaskStatus`.
 * @param runId - The unique ID of the mission run for which the task status has to be fetched.
 * @param taskId - The unique ID of the task for which the status has to be fetched.
 * @return Returns a Promise which resolves to TaskStatus object if successful, else rejects with an Error.
 */
export async function getTaskStatus(
  runId: number,
  taskId: number
): Promise<TaskStatus> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!runId)
    return Promise.reject(new ValidationError('Mission run ID is required'))
  if (!taskId) return Promise.reject(new ValidationError('Task ID is required'))
  let req = new GetMissionTaskStatusReq()
  req.runId = runId
  req.taskId = taskId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.FAILURE) ||
    (rsp && rsp.perf == Performative.REFUSE)
  )
    return Promise.reject(
      new ValidationError('Request failed: MissionTaskStatusReq(runId)')
    )
  rsp.taskList.forEach((t: any) =>
    t.taskType.indexOf('(') > -1
      ? (t.taskType = t.taskType.slice(0, t.taskType.indexOf('(')))
      : t.taskType
  )
  return rsp.taskList
}

/** To calculate the distance between 2 GPS coordinates */
function getDistanceFromLatLon(from: Coordinates, to: Coordinates): number {
  var d = 0
  if (from.length != to.length) return 0
  for (let i = 0; i < from.length; i++) {
    let dx = Math.abs(from[i] - to[i])
    d += dx * dx
  }
  return Math.sqrt(d)
}

/* To convert the GPS coordinates to local coordinates */
async function lnglat2xy(
  lnglat: Coordinates,
  origin: Coordinates
): Promise<Coordinates> {
  let cmd =
    'gps = new org.arl.unet.utils.GpsLocalFrame(' +
    [origin[1], origin[0]] +
    ');gps.toLocal(' +
    lnglat[1] +
    ', ' +
    lnglat[0] +
    ');'
  let shr = new ShellExecReq()
  shr.ans = true
  shr.recipient = gw.agent('astridsh')
  shr.cmd = cmd.toString()
  let rsp = await gw.request(shr, REQ_TIMEOUT)
  return rsp.ans
}

/* To convert the local coordinates to GPS coordinates */
function xy2lnglat(xy: Coordinates, lnglat: Coordinates): Coordinates {
  let rlat = (lnglat[1] * Math.PI) / 180
  let yScale =
    111132.92 -
    559.82 * Math.cos(2 * rlat) +
    1.175 * Math.cos(4 * rlat) -
    0.0023 * Math.cos(6 * rlat)
  let xScale =
    111412.84 * Math.cos(rlat) -
    93.5 * Math.cos(3 * rlat) +
    0.118 * Math.cos(5 * rlat)
  let lat = xy[1] / yScale + lnglat[1]
  let lng = xy[0] / xScale + lnglat[0]
  return [lng, lat]
}
