import { User, UserAdd, UserProfile, UserUpdate } from './types'
import { GetUserReq, PutUserReq, GetFileReq } from './messages'
import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'
import { haveIntersection } from './utils'

/**
 * This method fetches the list for all existing users to display in the User management page.
 * @return Returns a Promise which resolves to an array of User objects if successful, else rejects with an Error.
 **/
export const getAllUsers = async (): Promise<User[]> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  let users: User[] = []
  let req = new GetUserReq()
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetUserReq failed'))
  }
  ;(rsp.users as User[]).forEach((u: User) => {
    let data: User = {
      id: u.id,
      username: u.username,
      password: u.password,
      fullName: u.fullName,
      department: u.department,
      organization: u.organization,
      roles: u.roles,
      swanbots: u.swanbots || [],
      enabled: u.enabled,
    }
    users.push(data)
  })

  return users
}

/**
 * This method allows the System administrator to add a new user.
 * @param user
 * @param user.roles - At least 1 role ID is required while adding a user.
 * @return Returns a Promise which resolves to the `id` of the new user if successful,
 * else rejects with an Error.
 */
export const addUser = async (user: UserAdd): Promise<number> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!user.username) {
    return Promise.reject(new ValidationError('Username is required.'))
  }
  if (!user.fullName) {
    return Promise.reject(new ValidationError('Full name is required.'))
  }
  if (!user.roles || !user.roles.length) {
    return Promise.reject(
      new ValidationError('Atleast 1 role has to be assigned.')
    )
  }

  let req = new PutUserReq()
  req.username = user.username
  req.fullName = user.fullName
  req.roles = user.roles
  req.swanbots = []
  req.department = user.department ? user.department : null
  req.organization = user.organization ? user.organization : null
  req.enabled = true
  req.operation = 'INSERT'
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutUserReq(INSERT) failed:'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return rsp.id
}

/**
 * This method allows the System administrator to update the user details.
 * Only the fullName, role, list of SwanBots, department, organization
 * and enabled flag of the User can be updated using this function
 * and the remaining fields will be ignored.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export const updateUser = async (
  id: number,
  user: UserUpdate
): Promise<boolean> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) {
    return Promise.reject(new ValidationError('User Id is required.'))
  }
  let req = new PutUserReq()
  req.id = id
  if (user.fullName) req.fullName = user.fullName
  if (user.roles) req.roles = user.roles
  if (user.swanbots) req.swanbots = user.swanbots
  if (user.department) req.department = user.department
  if (user.organization) req.organization = user.organization
  if ('enabled' in user) req.enabled = user.enabled
  req.operation = 'UPDATE'
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutUserReq(UPDATE) failed:'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}

/**
 * This method allows a System administrator to delete a user.
 * @param {number} id - user Id.
 * @return {Promise<Boolean|Error>} Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export const deleteUser = async (id: number): Promise<boolean> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!id) {
    return Promise.reject(new ValidationError('User ID is required'))
  }

  let req = new PutUserReq()
  req.id = id
  req.operation = 'DELETE'
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutUserReq for DELETE failed'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  return true
}

/**
 * Download activity log for User.
 * @return Returns a Promise which resolves to a Uint8Array if successful, else rejects with an Error.
 */
export async function downloadUserLog(): Promise<Uint8Array> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['SYSTEM ADMINISTRATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  var gfr = new GetFileReq()
  gfr.recipient = gw.agent('astridsh')
  gfr.filename = 'swanbot-logs/DAISY/20201217.txt'
  let rsp = await gw.request(gfr)
  if (rsp.perf != Performative.INFORM) return new Uint8Array()
  let filecontents = new Uint8Array(rsp.contents)
  return filecontents
}

/**
 * This method allows the System administrator to fetch the user details to be displayed in the User management page. Only the system administrator will be allowed to use this method to fetch the user details for view/edit.
 * @return Returns a Promise which resolves to the User object if successful, else rejects with an Error.
 **/
export async function getUser(userId: number): Promise<User> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  if (!userId) {
    return Promise.reject(new ValidationError('User ID is required'))
  }

  let req = new GetUserReq()
  req.id = userId
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || !rsp.users || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetUserReq failed'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }

  return {
    id: userId,
    username: rsp.users[0].username,
    password: rsp.users[0].password,
    fullName: rsp.users[0].fullName,
    department: rsp.users[0].department,
    organization: rsp.users[0].organization,
    roles: rsp.users[0].roles,
    swanbots: rsp.users[0].swanbots || [],
    enabled: rsp.users[0].enabled,
    pwdChangePending: rsp.users[0].pwdChangePending,
  }
}

/**
 * This methods fetches the user details when the logged in user opens the `Profile` tab in the User Settings page.
 * @return Returns a Promise which resolves to the UserProfile object if successful, else rejects with an Error.
 **/
export async function getUserProfile(): Promise<UserProfile> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let req = new GetUserReq()
  req.id = getUserId()
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || !rsp.users || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('GetUserReq failed'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }

  return {
    id: getUserId(),
    username: rsp.users[0].username,
    fullName: rsp.users[0].fullName,
    department: rsp.users[0].department,
    organization: rsp.users[0].organization,
    phoneNo: rsp.users[0].phoneNo,
    enableEmailNtf: rsp.users[0].enableEmailNtf,
    enablePhoneNtf: rsp.users[0].enablePhoneNtf,
    enabled: rsp.users[0].enabled,
    is2FAEnabled: rsp.users[0].is2FAEnabled,
  }
}

/**
 * This method allows the respective users to update the user profile details displayed in the `Profile` tab of User Settings page. The fullName, enablePhoneNtf, enableEmailNtf and phoneNo of the user can be updated using this function and the remaining fields will be ignored.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function updateUserSetting(user: UserProfile): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  if (!user.fullName) {
    return Promise.reject(new ValidationError('Full name is required.'))
  }
  let req = new PutUserReq()
  req.id = getUserId()
  req.fullName = user.fullName
  req.phoneNo = user.phoneNo
  req.enableEmailNtf = user.enableEmailNtf
  req.enablePhoneNtf = user.enablePhoneNtf
  req.operation = 'UPDATE'
  req.recipient = gw.agent('admin')
  let rsp = await gw.request(req, REQ_TIMEOUT)

  if (!rsp || (rsp && rsp.perf == Performative.FAILURE)) {
    return Promise.reject(new ValidationError('PutUserReq failed:'))
  }

  if (rsp && rsp.perf == Performative.REFUSE) {
    return Promise.reject(
      new ValidationError('Please check if the inputs are valid')
    )
  }

  return true
}
