import { Coordinates } from './types'
import { HOST, PORT } from './gateway'

export const isDefined = <T>(p: T): p is Exclude<T, undefined> =>
  p !== undefined

export const invertCoordinates = (coordinates: Coordinates): Coordinates =>
  [...coordinates].reverse() as Coordinates

export const relativeUrlToAbsolute = (relativeUrl: string): string =>
  `//${HOST}:${PORT}/${relativeUrl}`

export const haveIntersection = <T>(a: T[], b: T[]) =>
  a.filter(Set.prototype.has, new Set(b)).length > 0

export function imageSrc(imageBuf: number[], mimeType: string): string {
  const b64 = btoa(
    new Uint8Array(imageBuf).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  )
  return `data:image/${mimeType};base64, ${b64}`
}
