import {
  AbortToHomeReq,
  MissionControlReq,
  MissionCtrlFailureNtf,
  PutOperationRsp,
} from './messages'
import { gw } from './gateway'
import { REQ_TIMEOUT } from './timeout'
import { Performative } from './fjage'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId, debug } from './common'
import { haveIntersection } from './utils'

export type RunMissionParams = {
  areaId: number
  missionId: number
  geofenceId: number
  swanbotId: number
}

/**
 * Run the mission.
 * @return Returns a Promise which resolves to the `runID` of the new mission run if successful else rejects with an Error.
 */
export const runMission = async ({
  areaId,
  missionId,
  geofenceId,
  swanbotId,
}: RunMissionParams): Promise<number> => {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new MissionControlReq()
  req.areaId = areaId
  req.missionId = missionId
  req.geofenceId = geofenceId
  req.swanbotId = swanbotId
  req.command = 'start'
  req.startedUserId = getUserId()
  req.recipient = gw.agent('bot')
  console.info('swanviz: send request object to the backend', req)
  try {
    const rsp = await gw.request(req, REQ_TIMEOUT)
    if (
      rsp &&
      rsp.perf != Performative.FAILURE &&
      rsp.perf != Performative.REFUSE
    ) {
      return rsp.id
    } else {
      const errNtf = await gw.receive(MissionCtrlFailureNtf, 10000)
      if (debug) console.log('MissionCtrlFailureNtf:' + errNtf)
      if (errNtf) return Promise.reject(new ValidationError(errNtf.reason))
      return Promise.reject(
        new ValidationError('Invalid request: MissionControlReq(START)')
      )
    }
  } catch (e) {
    return Promise.reject(e)
  }
}

/**
 * Suspend the mission.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function suspendMission(runId: number): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new MissionControlReq()
  req.runId = runId
  req.command = 'suspend'
  req.recipient = gw.agent('bot')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp instanceof MissionCtrlFailureNtf)) {
    return Promise.reject(
      new ValidationError('Invalid request: MissionControlReq(SUSPEND)')
    )
  }
  return true
}

/**
 * Resume the mission.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function resumeMission(runId: number): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new MissionControlReq()
  req.runId = runId
  req.command = 'resume'
  req.recipient = gw.agent('bot')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp instanceof MissionCtrlFailureNtf)) {
    return Promise.reject(
      new ValidationError('Invalid request: MissionControlReq(RESUME)')
    )
  }
  return true
}

/**
 * Abort the mission.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function abortMission(
  runId: number | null,
  swanbotId: number
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new MissionControlReq()
  if (runId) req.runId = runId
  req.swanbotId = swanbotId
  req.command = 'abort'
  req.stoppedUserId = getUserId()
  req.recipient = gw.agent('bot')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp instanceof MissionCtrlFailureNtf)) {
    return Promise.reject(
      new ValidationError('Invalid request: MissionControlReq(ABORT)')
    )
  }
  return true
}

/**
 * Abort the mission and go home.
 * @param runId - The mission run ID, if the SwanBot is 'IN A MISSION'.
 * @param swanbotId - The SwanBot ID which has to be sent to home location.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function abortToHomeMission(
  runId: number | null,
  swanbotId: number
): Promise<boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  const req = new AbortToHomeReq()
  if (runId) req.runId = runId
  req.swanbotId = swanbotId
  req.stoppedUserId = getUserId()
  req.recipient = gw.agent('bot')
  const rsp = await gw.request(req, REQ_TIMEOUT)
  if (
    !rsp ||
    (rsp && rsp.perf == Performative.REFUSE) ||
    (rsp && rsp.perf == Performative.FAILURE)
  ) {
    return Promise.reject(
      new ValidationError('Invalid request: AbortToHomeReq')
    )
  }
  return true
}
