/** Error type for authentication failure */
export class AuthenticationError extends Error {
  constructor(m: string) {
    super(m)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AuthenticationError.prototype)
  }
}

/** Error type for authorization failure */
export class AuthorizationError extends Error {
  constructor(m: string) {
    super(m)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AuthorizationError.prototype)
  }
}

/** Error type for validation failure */
export class ValidationError extends Error {
  constructor(m: string) {
    super(m)

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ValidationError.prototype)
  }
}
