import { Performative } from './fjage'
import { gw } from './gateway'
import { GetAlertSettingReq, PutAlertSettingReq } from './messages'
import { AlertSetting } from './types'
import { REQ_TIMEOUT } from './timeout'
import {
  AuthenticationError,
  AuthorizationError,
  ValidationError,
} from './error-types'
import { isAuthenticated, getUserRole, getUserId } from './common'
import { haveIntersection } from './utils'

/**
 * This method performs the following operations related to the `Alerts` tab in the SwanBot info card.
 	* - Adds the alert setting for the user and the swanbot.
 	* - Updates the existing alert setting for user and swanbot when, a) sensor parameters are added to the existing setting, b) sensor parameters are deleted from existing setting, c) threshold range is updated for any sensor parameter in the existing setting.
 	*  - Deletes the alert setting for the user and the swanbot if all sensor parameters are removed.

 	* For the above operations, the SensorParameter[] is expected to have all the sensor parameters set in the Alerts tab when `Save` is clicked in `Alerts` tab. For example, SwanBot `Elizabeth` have alert settings for `Blue green algae`, `Chlorophyll` and `HDO`.
 	* - If `Chlorophyll` setting is deleted, the SensorParameter[] should include only the `Blue green algae` and `HDO` in the update request.
 	* - If the range of `HDO` has to be updated, the SensorParameter[] should include the `HDO` with the updated range and the `Blue green algae` with the existing values, in the update request.
 	* - Send empty SensorParameter[] for deleting all settings of the SwanBot for the user.
 * @return Returns a Promise which resolves to true if successful, else rejects with an Error.
 */
export async function updateAlertSetting(
  swanbotId: number,
  threshold: AlertSetting[]
): Promise<Boolean> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!swanbotId) {
    return Promise.reject(new ValidationError('SwanBot ID is required.'))
  }
  if (threshold.length) {
    for (const alertSetting of threshold) {
      if (!alertSetting.id) {
        return Promise.reject(
          new ValidationError('Please enter valid id for the sensor parameter.')
        )
      }
      if (typeof alertSetting.min !== 'number') {
        return Promise.reject(
          new ValidationError(
            'Please enter minimum value for the sensor parameter.'
          )
        )
      }
      if (typeof alertSetting.max !== 'number') {
        return Promise.reject(
          new ValidationError(
            'Please enter maximum value for the sensor parameter.'
          )
        )
      }
    }
  }
  let req = new PutAlertSettingReq()
  req.userId = getUserId()
  req.swanbotId = swanbotId
  req.sensorParams = threshold
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req, REQ_TIMEOUT)
  if (!rsp || (rsp && rsp.perf == Performative.FAILURE))
    return Promise.reject(new ValidationError('PutAlertSettingReq failed.'))
  if (rsp && rsp.perf == Performative.REFUSE)
    return Promise.reject(
      new ValidationError('Please check if inputs are valid')
    )
  return true
}

/**
 * Fetches all the alert settings for a user and the given SwanBot.
 * @return Returns a Promise which resolves to an array of the AlertSetting objects if successful, else rejects with an Error.
 */
export async function getAlertSettings(
  swanbotId: number
): Promise<AlertSetting[]> {
  if (!isAuthenticated()) {
    return Promise.reject(new AuthenticationError('Authentication failed.'))
  }
  let userRole = getUserRole()
  if (!haveIntersection(userRole, ['CONTROLLER', 'ANALYSER', 'OPERATOR'])) {
    return Promise.reject(
      new AuthorizationError('User is not authorized for this operation.')
    )
  }
  if (!swanbotId) {
    return Promise.reject(new ValidationError('SwanBot ID is required.'))
  }
  let req = new GetAlertSettingReq()
  req.userId = getUserId()
  req.swanbotId = swanbotId
  req.recipient = gw.agent('astrid')
  let rsp = await gw.request(req)
  return rsp.threshold || []
}
